import React from 'react'

function RightSideBar() {
  return (
    <div className="SymbolPage_sidebar__BMi4v tabletSm-down|hidden flex flex-col max-w-[300px] gap-2.5">
  <br />
  <div role="presentation">
    <div className="NewsBucketCompact_container__w_DxG relative bg-primary-background text-primary-text overflow-hidden rounded-md pb-2 NewsBucketCompact_containerBorder__XpYYs border-primary-hairline border">
      <div className="NewsBucketCompactHeader_container__bYeeJ pt-4 pb-2 px-4 w-full flex justify-between items-center bg-primary-background text-primary-text text-sm">
        <h2 className="NewsBucketCompactHeader_title__PrvOb font-semibold">
          Latest RGTI News
        </h2>
        <a
          className="NewsBucketCompactHeader_more__8_psJ cursor-pointer"
          href="/symbol/RGTI/news"
        >
          View
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="chevron-right"
            className="svg-inline--fa fa-chevron-right NewsBucketCompactHeader_icon__TttqM ml-1 text-xxs"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z"
            />
          </svg>
        </a>
      </div>
      <a
        rel="noopener nofollow"
        target="_blank"
        href="https://stocktwits.com/news-articles/markets/equity/rigetti-shares-fall-after-citron-short-seller-critique/cJa31zmR0X"
        className="block"
      />
      <div className="NewsItemCompact_container__yzpG3 relative cursor-pointer flex flex-row justify-start h-auto p-3 gap-3 justify-between h-full ShareNewsDropdown_shareNewsHoverContainer__Q6kpz">
        <a
          rel="noopener nofollow"
          target="_blank"
          href="https://stocktwits.com/news-articles/markets/equity/rigetti-shares-fall-after-citron-short-seller-critique/cJa31zmR0X"
          className="block"
        />
        <div className="flex flex-col justify-between items-start NewsItemCompact_textContainer__yqO2q relative grow">
          <a
            rel="noopener nofollow"
            target="_blank"
            href="https://stocktwits.com/news-articles/markets/equity/rigetti-shares-fall-after-citron-short-seller-critique/cJa31zmR0X"
            className="block"
          >
            <div className="NewsItemCompact_shareContainerCompact__G9EiH absolute right-3 bottom-1">
              <div className="ShareNewsDropdown_share__hc_qg w-2.5 h-2.5 text-grey-9 text-xs transition duration-150 ease-in-out transition-opacity opacity-0">
                <span className="Dropdown_trigger__umZTP cursor-pointer">
                  <div>
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.555 0.135231C6.74733 -0.0450771 7.04662 -0.0450771 7.23894 0.135231L11.2389 3.88523C11.4404 4.0741 11.4506 4.39051 11.2617 4.59197C11.0729 4.79343 10.7565 4.80363 10.555 4.61477L7.39697 1.65412V9.5C7.39697 9.77614 7.17312 10 6.89697 10C6.62083 10 6.39697 9.77614 6.39697 9.5V1.65412L3.23894 4.61477C3.03749 4.80363 2.72107 4.79343 2.53221 4.59197C2.34334 4.39051 2.35355 4.0741 2.555 3.88523L6.555 0.135231Z"
                        fill="currentColor"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3 13C2.17157 13 1.5 12.3284 1.5 11.5V9.5C1.5 9.22386 1.27614 9 1 9C0.723858 9 0.5 9.22386 0.5 9.5V11.5C0.5 12.8807 1.61929 14 3 14H11C12.3807 14 13.5 12.8807 13.5 11.5V9.5C13.5 9.22386 13.2761 9 13 9C12.7239 9 12.5 9.22386 12.5 9.5V11.5C12.5 12.3284 11.8284 13 11 13H3Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </span>
              </div>
            </div>
            <div className="NewsItemCompact_bolder__ar70j font-semibold text-sm mb-2 line-clamp-5">
              <span>
                Rigetti Shares Fall After Short Seller Critique: Retail Only
                Gets More Pumped Up
              </span>
            </div>
          </a>
          <div className="flex flex-col justify-start items-start">
            <a
              rel="noopener nofollow"
              target="_blank"
              href="https://stocktwits.com/news-articles/markets/equity/rigetti-shares-fall-after-citron-short-seller-critique/cJa31zmR0X"
              className="block"
            >
              <div className="flex flex-row justify-start items-center NewsItem_info__lsjJW text-st-tertiary-text text-xs whitespace-nowrap flex-wrap">
                <span className="NewsItem_iconContainer__hvgmb w-1.5 h-1.5 mb-1.5 mr-2 text-primary-text">
                  <svg viewBox="0 0 144 300" xmlns="http://www.w3.org/2000/svg">
                    <title id="stocktwits-symbol">StockTwits Symbol</title>
                    <g
                      transform="translate(0.678571, 0.000000)"
                      fillRule="nonzero"
                    >
                      <polygon
                        fill="currentColor"
                        points="142.714286 174.857143 -1.42108547e-14 101.357143 -1.42108547e-14 74.3571429 142.071429 -1.42108547e-14 142.285714 48 59.3571429 87.4285714 142.5 126.642857"
                      />
                      <polygon
                        fill="#218DFF"
                        points="142.714286 225.214286 0.642857143 299.785714 0.428571429 252 81.2142857 212.357143 0.214285714 173.571429 -1.42108547e-14 124.928571 142.5 198.428571"
                      />
                    </g>
                  </svg>
                </span>
                <span className="mr-1 NewsItem_stSource__Qoc5f font-semibold text-st-primary-text">
                  stocktwits.com
                </span>
                <span className="mr-1">·</span>
                <span>1d ago</span>
              </div>
            </a>
            <a
              rel="noopener nofollow"
              target="_blank"
              href="https://stocktwits.com/news-articles/markets/equity/rigetti-shares-fall-after-citron-short-seller-critique/cJa31zmR0X"
            >
              <button
                className="Button_button__mg_cR flex items-center justify-center border border-transparent rounded-full text-center font-semibold text-sm leading-4 cursor-pointer w-100 Button_xxs__S0xzW Button_secondary__kYMEY bg-secondary-button-background text-secondary-button-text hover|bg-secondary-button-hover hover|text-secondary-button-hover-text border !border-secondary-button-border NewsItem_itemButton__0gy0e !text-[12px] mt-1"
                type="button"
              >
                Full Article
              </button>
            </a>
          </div>
        </div>
        <div className="NewsItemCompact_compactImageContainer__KTq4B">
          <div>
            <img
              alt="News Image"
              className="NewsItemCompact_image__V93Of NewsItem_image__AhQa6 object-cover overflow-hidden w-full cursor-pointer rounded-md"
              src="https://sih-st-charts.stocktwits-cdn.com/fit-in/140x0/filters:quality(75)/https%3A%2F%2Fst-everywhere-cms-prod.s3.us-east-1.amazonaws.com%2Frigetti_computing_quantum_computer_representative_resized_bafe11454b.jpg"
            />
          </div>
        </div>
      </div>
      <a
        rel="noopener nofollow"
        target="_blank"
        href="https://finance.yahoo.com/news/gamestop-us-steel-walgreens-rigetti-024639284.html"
        className="block"
      >
        <div className="NewsItemCompact_container__yzpG3 relative cursor-pointer flex flex-row justify-start h-auto p-3 gap-3 justify-between h-full ShareNewsDropdown_shareNewsHoverContainer__Q6kpz">
          <div className="flex flex-col justify-between items-start NewsItemCompact_textContainer__yqO2q relative grow">
            <div className="NewsItemCompact_shareContainerCompact__G9EiH absolute right-3 bottom-1">
              <div className="ShareNewsDropdown_share__hc_qg w-2.5 h-2.5 text-grey-9 text-xs transition duration-150 ease-in-out transition-opacity opacity-0">
                <span className="Dropdown_trigger__umZTP cursor-pointer">
                  <div>
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.555 0.135231C6.74733 -0.0450771 7.04662 -0.0450771 7.23894 0.135231L11.2389 3.88523C11.4404 4.0741 11.4506 4.39051 11.2617 4.59197C11.0729 4.79343 10.7565 4.80363 10.555 4.61477L7.39697 1.65412V9.5C7.39697 9.77614 7.17312 10 6.89697 10C6.62083 10 6.39697 9.77614 6.39697 9.5V1.65412L3.23894 4.61477C3.03749 4.80363 2.72107 4.79343 2.53221 4.59197C2.34334 4.39051 2.35355 4.0741 2.555 3.88523L6.555 0.135231Z"
                        fill="currentColor"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3 13C2.17157 13 1.5 12.3284 1.5 11.5V9.5C1.5 9.22386 1.27614 9 1 9C0.723858 9 0.5 9.22386 0.5 9.5V11.5C0.5 12.8807 1.61929 14 3 14H11C12.3807 14 13.5 12.8807 13.5 11.5V9.5C13.5 9.22386 13.2761 9 13 9C12.7239 9 12.5 9.22386 12.5 9.5V11.5C12.5 12.3284 11.8284 13 11 13H3Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </span>
              </div>
            </div>
            <div className="NewsItemCompact_bolder__ar70j font-semibold text-sm mb-2 line-clamp-5">
              <span>
                Gamestop, US Steel, Walgreens, Rigetti Computing, Tesla: Why
                These 5 Stocks Are On Investors' Radars Today
              </span>
            </div>
            <div className="flex flex-col justify-start items-start">
              <div className="flex flex-row justify-start items-center NewsItem_info__lsjJW text-st-tertiary-text text-xs whitespace-nowrap flex-wrap">
                <span className="mr-1">Yahoo! Finance: News</span>
                <span className="mr-1">·</span>
                <span>2d ago</span>
              </div>
            </div>
          </div>
          <div className="NewsItemCompact_compactImageContainer__KTq4B">
            <div>
              <div className="NewsItem_image__AhQa6 object-cover overflow-hidden w-full cursor-pointer NewsItem_emptyImage__3qOGL flex justify-center items-center bg-news-item-background !border-0 rounded-md NewsItemCompact_image__V93Of">
                <img
                  alt="News Placeholder"
                  src="https://chunks-prd.stocktwits-cdn.com/_next/static/media/news-placeholder.eb4d81c7.svg"
                  className="NewsItem_compactNewsIcon__XreBW w-10 h-[33px]"
                />
              </div>
            </div>
          </div>
        </div>
      </a>
      <a
        rel="noopener nofollow"
        target="_blank"
        href="https://stocktwits.com/news-articles/markets/equity/rigetti-computing-stock-hits-record-high-on-quantum-ai-breakthrough/cJa2fweRix"
        className="block"
      />
      <div className="NewsItemCompact_container__yzpG3 relative cursor-pointer flex flex-row justify-start h-auto p-3 gap-3 justify-between h-full ShareNewsDropdown_shareNewsHoverContainer__Q6kpz">
        <a
          rel="noopener nofollow"
          target="_blank"
          href="https://stocktwits.com/news-articles/markets/equity/rigetti-computing-stock-hits-record-high-on-quantum-ai-breakthrough/cJa2fweRix"
          className="block"
        />
        <div className="flex flex-col justify-between items-start NewsItemCompact_textContainer__yqO2q relative grow">
          <a
            rel="noopener nofollow"
            target="_blank"
            href="https://stocktwits.com/news-articles/markets/equity/rigetti-computing-stock-hits-record-high-on-quantum-ai-breakthrough/cJa2fweRix"
            className="block"
          >
            <div className="NewsItemCompact_shareContainerCompact__G9EiH absolute right-3 bottom-1">
              <div className="ShareNewsDropdown_share__hc_qg w-2.5 h-2.5 text-grey-9 text-xs transition duration-150 ease-in-out transition-opacity opacity-0">
                <span className="Dropdown_trigger__umZTP cursor-pointer">
                  <div>
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.555 0.135231C6.74733 -0.0450771 7.04662 -0.0450771 7.23894 0.135231L11.2389 3.88523C11.4404 4.0741 11.4506 4.39051 11.2617 4.59197C11.0729 4.79343 10.7565 4.80363 10.555 4.61477L7.39697 1.65412V9.5C7.39697 9.77614 7.17312 10 6.89697 10C6.62083 10 6.39697 9.77614 6.39697 9.5V1.65412L3.23894 4.61477C3.03749 4.80363 2.72107 4.79343 2.53221 4.59197C2.34334 4.39051 2.35355 4.0741 2.555 3.88523L6.555 0.135231Z"
                        fill="currentColor"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3 13C2.17157 13 1.5 12.3284 1.5 11.5V9.5C1.5 9.22386 1.27614 9 1 9C0.723858 9 0.5 9.22386 0.5 9.5V11.5C0.5 12.8807 1.61929 14 3 14H11C12.3807 14 13.5 12.8807 13.5 11.5V9.5C13.5 9.22386 13.2761 9 13 9C12.7239 9 12.5 9.22386 12.5 9.5V11.5C12.5 12.3284 11.8284 13 11 13H3Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </span>
              </div>
            </div>
            <div className="NewsItemCompact_bolder__ar70j font-semibold text-sm mb-2 line-clamp-5">
              <span>
                Rigetti Computing Stock Soars To Record High After Quantum AI
                Breakthrough: Retail Bulls Eye ‘New Gold Rush’
              </span>
            </div>
          </a>
          <div className="flex flex-col justify-start items-start">
            <a
              rel="noopener nofollow"
              target="_blank"
              href="https://stocktwits.com/news-articles/markets/equity/rigetti-computing-stock-hits-record-high-on-quantum-ai-breakthrough/cJa2fweRix"
              className="block"
            >
              <div className="flex flex-row justify-start items-center NewsItem_info__lsjJW text-st-tertiary-text text-xs whitespace-nowrap flex-wrap">
                <span className="NewsItem_iconContainer__hvgmb w-1.5 h-1.5 mb-1.5 mr-2 text-primary-text">
                  <svg viewBox="0 0 144 300" xmlns="http://www.w3.org/2000/svg">
                    <title id="stocktwits-symbol">StockTwits Symbol</title>
                    <g
                      transform="translate(0.678571, 0.000000)"
                      fillRule="nonzero"
                    >
                      <polygon
                        fill="currentColor"
                        points="142.714286 174.857143 -1.42108547e-14 101.357143 -1.42108547e-14 74.3571429 142.071429 -1.42108547e-14 142.285714 48 59.3571429 87.4285714 142.5 126.642857"
                      />
                      <polygon
                        fill="#218DFF"
                        points="142.714286 225.214286 0.642857143 299.785714 0.428571429 252 81.2142857 212.357143 0.214285714 173.571429 -1.42108547e-14 124.928571 142.5 198.428571"
                      />
                    </g>
                  </svg>
                </span>
                <span className="mr-1 NewsItem_stSource__Qoc5f font-semibold text-st-primary-text">
                  stocktwits.com
                </span>
                <span className="mr-1">·</span>
                <span>2d ago</span>
              </div>
            </a>
            <a
              rel="noopener nofollow"
              target="_blank"
              href="https://stocktwits.com/news-articles/markets/equity/rigetti-computing-stock-hits-record-high-on-quantum-ai-breakthrough/cJa2fweRix"
            >
              <button
                className="Button_button__mg_cR flex items-center justify-center border border-transparent rounded-full text-center font-semibold text-sm leading-4 cursor-pointer w-100 Button_xxs__S0xzW Button_secondary__kYMEY bg-secondary-button-background text-secondary-button-text hover|bg-secondary-button-hover hover|text-secondary-button-hover-text border !border-secondary-button-border NewsItem_itemButton__0gy0e !text-[12px] mt-1"
                type="button"
              >
                Full Article
              </button>
            </a>
          </div>
        </div>
        <div className="NewsItemCompact_compactImageContainer__KTq4B">
          <div>
            <img
              alt="News Image"
              className="NewsItemCompact_image__V93Of NewsItem_image__AhQa6 object-cover overflow-hidden w-full cursor-pointer rounded-md"
              src="https://sih-st-charts.stocktwits-cdn.com/fit-in/140x0/filters:quality(75)/https%3A%2F%2Fst-everywhere-cms-prod.s3.us-east-1.amazonaws.com%2Frigetti_computing_quantum_computer_representative_resized_bafe11454b.jpg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sticky-outer-wrapper">
    <div
      className="sticky-inner-wrapper"
      style={{ position: "relative", transform: "translate3d(0px, 0px, 0px)" }}
    >
      <div className="relative SidebarRightAd_adClass__7j2lf min-h-[250px] w-[300px] leading-none">
        <div className="BackgroundLoaderAd_container__BFoDY overflow-hidden text-dark-grey-2 dark|text-grey-5 font-light rounded-md absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center bg-transparent SidebarRightAd_loaderClass__NUf_b min-h-[250px] w-[300px] z-[-1] rounded-none" />
        <div className="SidebarRightAd_adClass__7j2lf min-h-[250px] w-[300px] leading-none">
          <div
            data-aaad="true"
            data-aa-adunit="/22404395434/Stocktwits_Web/Stocktwits_Symbol_MidRight"
            data-aa-targeting="{'pathname':'/symbol/RGTI','instrumentClass':'Stock','instrumenttype':'Stock','pid':'symbol-rgti','quotetype':'ComputerProcessingHardware','s1':'symbol','ticker':'RGTI','averageDailyVolumeLast3Months':'35396000','cap':'small-cap','displayType':'lightMode','environment':'production','industry':'Computer Processing Hardware','loggedin':true,'marketCapitalization':1788,'mode':'light','pos':'MidRight','sector':'Electronic Technology','symbolPrice':6.685,'userticker':'RGTI','web':'new'}"
            data-pos="midright"
            className="flex justify-center SidebarRightAd_adClass__7j2lf min-h-[250px] w-[300px] leading-none"
            data-status="skipped"
            id="ca2b304d-0952-44d4-bd84-72aadd421351"
            data-aa-device='["desktop","tablet"]'
            data-aa-sizes="[[1,1]]"
            data-aa-lazy-loaded="false"
            data-aa-refresh-viewable={30}
            style={{ textAlign: "-webkit-center" }}
          />
        </div>
      </div>
      <p className="Disclosure_disclosure__GmLV4 Disclosure_disclosureCompact__7OUvM tabletSm-down|max-w-[300px] mx-auto text-center text-tertiary-text text-[10px] leading-[16px] pb-3">
        Advertisement.
        <span>
          {" "}
          <a
            className="Disclosure_removeAdsLink__3yG8W text-primary-text underline cursor-pointer"
            href="/subscriptions"
          >
            Remove ads.
          </a>
        </span>
      </p>
    </div>
  </div>
</div>

  )
}

export default RightSideBar