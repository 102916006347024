import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useLocation } from "react-router-dom";

function Charte({ props }) {
  const [Time, setTime] = useState("HH:MM DD MMM YYYY");
  const [Name, setName] = useState();

  const [state, setState] = React.useState({
    series: [
      {
        name: "STOCK ABC",
        data: [],
      },
    ],
    options: {
      labels: [],
      xaxis: {
        type: "category",
      },
    },
  });

  useEffect(() => {
    if (!props.oneday) return; // if token doesn't exist yet, don't call the api
    const timestamp = [];
    const value = [];

    const findLang = async () => {
      for (let index = 0; index < props.oneday.split(",").length; index++) {
        if (props.oneday.split(",")[index].includes(".")) {
          timestamp.push(props.oneday.split(",")[index]);
        } else {
          value.push(props.oneday.split(",")[index]);
        }
      }

      setState({
        series: [
          {
            name: props.symbol,
            data: timestamp,
          },
        ],
        options: {
          noData: {
            text: "Loading...",
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          labels: value,
          xaxis: {
            type: "datetime",
            tickAmount: 10,

          },
          
        },
      });
    };
    findLang();
  }, [props.oneday]);

  return (
    <div id="chart">
      {props && (
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="area"
          height={260}
        />
      )}
    </div>
  );
}

export default Charte;
