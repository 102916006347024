import React from 'react'

function TimeLine() {
  return (
    <div className="MainHeader_secondaryHeaderContainer__G0oQ4 border-t-0 MainHeader_topHairline__Jpw0E border-t border-primary-hairline">
  <div className="flex flex-row justify-center items-center full-marquee w-full">
    <div className="flex overflow-hidden">
      <div
        className="Trending_containerThin__lOpNq Trending_container__6jqOE flex items-center justify-between overflow-hidden whitespace-nowrap font-semibold text-xs tabletSm|py-2.5 py-2.5 Trending_tickersRowAnimation__TbhYQ Trending_enabledMarquee___Zas5 !overflow-visible"
        data-testid="trending-view"
      >
        <div className="h-[calc(100%+18px)]" />
        <div className="Trending_indicesShort__K_CXM flex flex-row items-center justify-between tabletSm|justify-between pl-5 pr-4 tabletSm-down|uppercase tabletSm-down|grow Trending_indicesShortUS__nOSfR w-[260px] Trending_disabledMarquee__6ilIf">
          <a
            className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
            href="/symbol/SPY"
          >
            <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
              <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                SPY
              </span>
              <span className="Trending_tickerChange__n6QUj block h-[16px]">
                <span
                  className="Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red"
                  aria-label="Change"
                >
                  <span
                    className="Change_arrow__AjLH9 relative flex items-center Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red text-[9px]"
                    role="presentation"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="arrow-down-long"
                      className="svg-inline--fa fa-arrow-down-long "
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        fill="currentColor"
                        d="M169.4 502.6c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 402.7 224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 370.7L86.6 329.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128z"
                      />
                    </svg>
                  </span>
                  <span
                    className="Change_amount__l5O_n whitespace-nowrap"
                    aria-label="Change"
                  >
                    0.40%
                  </span>
                </span>
              </span>
            </div>
          </a>
          <a
            className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
            href="/symbol/QQQ"
          >
            <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
              <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                QQQ
              </span>
              <span className="Trending_tickerChange__n6QUj block h-[16px]">
                <span
                  className="Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red"
                  aria-label="Change"
                >
                  <span
                    className="Change_arrow__AjLH9 relative flex items-center Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red text-[9px]"
                    role="presentation"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="arrow-down-long"
                      className="svg-inline--fa fa-arrow-down-long "
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        fill="currentColor"
                        d="M169.4 502.6c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 402.7 224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 370.7L86.6 329.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128z"
                      />
                    </svg>
                  </span>
                  <span
                    className="Change_amount__l5O_n whitespace-nowrap"
                    aria-label="Change"
                  >
                    0.49%
                  </span>
                </span>
              </span>
            </div>
          </a>
        </div>
        <div className="h-[calc(100%+18px)] Trending_divider__PaPqk w-px bg-primary-hairline mx-3" />
        <div className="Trending_trending__DXWES tabletSm-down|flex w-full flex-row grow-[2] gap-x-[15px] pr-6 border-l border-primary-hairline contents">
          <div className="flex flex-row justify-between items-center mr-2">
            <div className="Trending_trendingLabel__2JYHk tabletSm-down|hidden uppercase tabletSm|flex items-center Trending_updatedTrendingLabel__nZod8 text-[9px]">
              <span>Trending</span>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="circle-info"
                className="svg-inline--fa fa-circle-info Disclosures_icon__sUHXX ml-1 cursor-pointer !text-[12px]"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V248c0-13.3-10.7-24-24-24H216c-13.3 0-24 10.7-24 24s10.7 24 24 24h24v64H216zm40-144a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
                />
              </svg>
            </div>
          </div>
          <div className="flex">
            <div className="Trending_tickers__QRjxC flex mr-2 Trending_disabledMarquee__6ilIf">
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/ADBE"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <img
                    alt="Top trending ticker"
                    className="Trending_hot__FoH4L inline"
                    height={12}
                    src="https://chunks-prd.stocktwits-cdn.com/_next/static/media/hot.d13a6f1f.svg"
                    width={10}
                  />
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    ADBE
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-down-long"
                          className="svg-inline--fa fa-arrow-down-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M169.4 502.6c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 402.7 224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 370.7L86.6 329.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        14.37%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/PARA"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    PARA
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-up-long"
                          className="svg-inline--fa fa-arrow-up-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        3.62%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/AVGO"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    AVGO
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-down-long"
                          className="svg-inline--fa fa-arrow-down-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M169.4 502.6c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 402.7 224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 370.7L86.6 329.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        1.43%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/APVO"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    APVO
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-down-long"
                          className="svg-inline--fa fa-arrow-down-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M169.4 502.6c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 402.7 224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 370.7L86.6 329.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        39.46%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/AMD"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    AMD
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-up-long"
                          className="svg-inline--fa fa-arrow-up-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        0.57%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/HUT"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    HUT
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-up-long"
                          className="svg-inline--fa fa-arrow-up-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        6.33%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/KROS"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    KROS
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-down-long"
                          className="svg-inline--fa fa-arrow-down-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M169.4 502.6c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 402.7 224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 370.7L86.6 329.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        73.23%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/COST"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    COST
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-down-long"
                          className="svg-inline--fa fa-arrow-down-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M169.4 502.6c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 402.7 224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 370.7L86.6 329.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        0.07%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/HOOD"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    HOOD
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-up-long"
                          className="svg-inline--fa fa-arrow-up-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        4.70%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/WULF"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    WULF
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-up-long"
                          className="svg-inline--fa fa-arrow-up-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        5.13%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="Trending_containerThin__lOpNq Trending_container__6jqOE flex items-center justify-between overflow-hidden whitespace-nowrap font-semibold text-xs tabletSm|py-2.5 py-2.5 Trending_tickersRowAnimation__TbhYQ Trending_enabledMarquee___Zas5 !overflow-visible"
        data-testid="trending-view"
      >
        <div className="h-[calc(100%+18px)]" />
        <div className="Trending_indicesShort__K_CXM flex flex-row items-center justify-between tabletSm|justify-between pl-5 pr-4 tabletSm-down|uppercase tabletSm-down|grow Trending_indicesShortUS__nOSfR w-[260px] Trending_disabledMarquee__6ilIf">
          <a
            className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
            href="/symbol/SPY"
          >
            <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
              <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                SPY
              </span>
              <span className="Trending_tickerChange__n6QUj block h-[16px]">
                <span
                  className="Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red"
                  aria-label="Change"
                >
                  <span
                    className="Change_arrow__AjLH9 relative flex items-center Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red text-[9px]"
                    role="presentation"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="arrow-down-long"
                      className="svg-inline--fa fa-arrow-down-long "
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        fill="currentColor"
                        d="M169.4 502.6c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 402.7 224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 370.7L86.6 329.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128z"
                      />
                    </svg>
                  </span>
                  <span
                    className="Change_amount__l5O_n whitespace-nowrap"
                    aria-label="Change"
                  >
                    0.40%
                  </span>
                </span>
              </span>
            </div>
          </a>
          <a
            className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
            href="/symbol/QQQ"
          >
            <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
              <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                QQQ
              </span>
              <span className="Trending_tickerChange__n6QUj block h-[16px]">
                <span
                  className="Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red"
                  aria-label="Change"
                >
                  <span
                    className="Change_arrow__AjLH9 relative flex items-center Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red text-[9px]"
                    role="presentation"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="arrow-down-long"
                      className="svg-inline--fa fa-arrow-down-long "
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        fill="currentColor"
                        d="M169.4 502.6c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 402.7 224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 370.7L86.6 329.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128z"
                      />
                    </svg>
                  </span>
                  <span
                    className="Change_amount__l5O_n whitespace-nowrap"
                    aria-label="Change"
                  >
                    0.49%
                  </span>
                </span>
              </span>
            </div>
          </a>
        </div>
        <div className="h-[calc(100%+18px)] Trending_divider__PaPqk w-px bg-primary-hairline mx-3" />
        <div className="Trending_trending__DXWES tabletSm-down|flex w-full flex-row grow-[2] gap-x-[15px] pr-6 border-l border-primary-hairline contents">
          <div className="flex flex-row justify-between items-center mr-2">
            <div className="Trending_trendingLabel__2JYHk tabletSm-down|hidden uppercase tabletSm|flex items-center Trending_updatedTrendingLabel__nZod8 text-[9px]">
              <span>Trending</span>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="circle-info"
                className="svg-inline--fa fa-circle-info Disclosures_icon__sUHXX ml-1 cursor-pointer !text-[12px]"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V248c0-13.3-10.7-24-24-24H216c-13.3 0-24 10.7-24 24s10.7 24 24 24h24v64H216zm40-144a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
                />
              </svg>
            </div>
          </div>
          <div className="flex">
            <div className="Trending_tickers__QRjxC flex mr-2 Trending_disabledMarquee__6ilIf">
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/ADBE"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <img
                    alt="Top trending ticker"
                    className="Trending_hot__FoH4L inline"
                    height={12}
                    src="https://chunks-prd.stocktwits-cdn.com/_next/static/media/hot.d13a6f1f.svg"
                    width={10}
                  />
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    ADBE
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-down-long"
                          className="svg-inline--fa fa-arrow-down-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M169.4 502.6c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 402.7 224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 370.7L86.6 329.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        14.37%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/PARA"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    PARA
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-up-long"
                          className="svg-inline--fa fa-arrow-up-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        3.62%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/AVGO"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    AVGO
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-down-long"
                          className="svg-inline--fa fa-arrow-down-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M169.4 502.6c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 402.7 224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 370.7L86.6 329.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        1.43%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/APVO"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    APVO
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-down-long"
                          className="svg-inline--fa fa-arrow-down-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M169.4 502.6c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 402.7 224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 370.7L86.6 329.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        39.46%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/AMD"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    AMD
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-up-long"
                          className="svg-inline--fa fa-arrow-up-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        0.57%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/HUT"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    HUT
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-up-long"
                          className="svg-inline--fa fa-arrow-up-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        6.33%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/KROS"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    KROS
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-down-long"
                          className="svg-inline--fa fa-arrow-down-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M169.4 502.6c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 402.7 224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 370.7L86.6 329.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        73.23%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/COST"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    COST
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_negative__3Cz_W Change_container__GNa_f inline-flex gap-1 text-red text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-down-long"
                          className="svg-inline--fa fa-arrow-down-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M169.4 502.6c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 402.7 224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 370.7L86.6 329.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        0.07%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/HOOD"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    HOOD
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-up-long"
                          className="svg-inline--fa fa-arrow-up-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        4.70%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
              <a
                className="Trending_ticker__j67AD pl-[11px] first|pl-0 first|mr-2"
                href="/symbol/WULF"
              >
                <div className="Trending_tickerLabel__4iKnb flex gap-x-1 text-primary-text">
                  <span className="Trending_tickerName__Qgolo tabletMd-down|inline-block tabletMd-down|mr-0.25">
                    WULF
                  </span>
                  <span className="Trending_tickerChange__n6QUj block h-[16px]">
                    <span
                      className="Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green"
                      aria-label="Change"
                    >
                      <span
                        className="Change_arrow__AjLH9 relative flex items-center Change_positive__2H4d1 Change_container__GNa_f inline-flex gap-1 text-green text-[9px]"
                        role="presentation"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="arrow-up-long"
                          className="svg-inline--fa fa-arrow-up-long "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z"
                          />
                        </svg>
                      </span>
                      <span
                        className="Change_amount__l5O_n whitespace-nowrap"
                        aria-label="Change"
                      >
                        5.13%
                      </span>
                    </span>
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="flex flex-row justify-center items-center cursor-pointer Trending_marqueeToggle__P6j2f cursor-pointer !w-6 !h-6 !max-w-6 !max-h-6 rounded-full border border---primary-border dark|border-primary-hairline px-2 mx-3 !text-[10px]">
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="play"
        className="svg-inline--fa fa-play Trending_marqueeButton__y9GHY cursor-pointer text-primary-text Trending_playIcon__wJdbb ml-[2px]"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 384 512"
      >
        <path
          fill="currentColor"
          d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
        />
      </svg>
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="pause"
        className="svg-inline--fa fa-pause Trending_marqueeButton__y9GHY cursor-pointer text-primary-text Trending_pauseIcon__fXShN"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="currentColor"
          d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z"
        />
      </svg>
    </div>
  </div>
</div>

  )
}

export default TimeLine