import React from 'react'

function MainHeaderMobile() {
  return (
    <div className="MainHeader_mobileHeaderContainer___mWoe tabletMd|hidden">
  <div className="MobileHeader_container__jMTt_ relative text-primary-text border-b border-primary-hairline tabletMd-down|h-[52px] h-full">
    <nav className="MobileHeader_nav__T_c0N w-full h-full flex flex-row justify-between pr-2 items-center">
      <span>
        <button className="MobileHeader_navButton__g0EGn MobileHeader_navButtonBase__w6cJF h-[50px] flex justify-center items-center cursor-pointer w-[50px]">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 12 10"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            className="MobileHeader_icon__kG_Ap w-[17px] h-[13px]"
          >
            <path
              d="M1 1.3999H18.8"
              stroke="currentColor"
              strokeWidth="1.2"
              strokeLinecap="round"
            />
            <path
              d="M1 9.3999H18.8"
              stroke="currentColor"
              strokeWidth="1.2"
              strokeLinecap="round"
            />
          </svg>
        </button>
      </span>
      <div className="flex flex-row justify-between items-center flex">
        <a
          className="Button_button__mg_cR flex items-center justify-center border border-transparent rounded-full text-center font-semibold text-sm leading-4 cursor-pointer w-100 Button_md__6HsQu Button_secondary__kYMEY bg-secondary-button-background text-secondary-button-text hover|bg-secondary-button-hover hover|text-secondary-button-hover-text border !border-secondary-button-border MobileHeader_useAppButton__x8iPf !h-[32px] !w-[100px] px-4 my-auto font-semibold mr-2"
          href="https://stocktwits.onelink.me/NjIT/geja8ly0"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="mobile"
            className="svg-inline--fa fa-mobile MobileHeader_mobileIcon__tLrIo w-[17px] h-[13px] pr-1"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path
              fill="currentColor"
              d="M80 48c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H304c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H80zM16 64C16 28.7 44.7 0 80 0H304c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H80c-35.3 0-64-28.7-64-64V64zM160 400h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H160c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
            />
          </svg>
          Use App
        </a>
        <button className="MobileHeader_navMenuButton__521co MobileHeader_navButtonBase__w6cJF h-[50px] flex justify-center items-center cursor-pointer w-[35px]">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fal"
            data-icon="magnifying-glass"
            className="svg-inline--fa fa-magnifying-glass "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M384 208A176 176 0 1 0 32 208a176 176 0 1 0 352 0zM343.3 366C307 397.2 259.7 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 51.7-18.8 99-50 135.3L507.3 484.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L343.3 366z"
            />
          </svg>
        </button>
      </div>
    </nav>
    <div className="MobileHeader_center__l_JVA absolute top-2/4 left-2/4 transform -translate-x-2/4 -translate-y-2/4 text-primary-text flex justify-center items-end cursor-pointer">
      <span className="absolute opacity-0 pointer-events-none">Stocktwits</span>
      <a
        title="Stocktwits"
        className="MobileHeader_logoLink__1wldO block w-full h-full p-2"
        href="/"
      >
        <svg viewBox="0 0 144 300" xmlns="http://www.w3.org/2000/svg">
          <title id="stocktwits-symbol">StockTwits Symbol</title>
          <g transform="translate(0.678571, 0.000000)" fillRule="nonzero">
            <polygon
              fill="currentColor"
              points="142.714286 174.857143 -1.42108547e-14 101.357143 -1.42108547e-14 74.3571429 142.071429 -1.42108547e-14 142.285714 48 59.3571429 87.4285714 142.5 126.642857"
            />
            <polygon
              fill="#218DFF"
              points="142.714286 225.214286 0.642857143 299.785714 0.428571429 252 81.2142857 212.357143 0.214285714 173.571429 -1.42108547e-14 124.928571 142.5 198.428571"
            />
          </g>
        </svg>
      </a>
    </div>
  </div>
</div>

  )
}

export default MainHeaderMobile