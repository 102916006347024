import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import MainHeader from "../Compenents/MainHeader";
import MainHeaderMobile from "../Compenents/MainHeaderMobile";
import TimeLine from "../Compenents/TimeLine";
import LeftSideBar from "../Compenents/LeftSideBar";
import RightSideBar from "../Compenents/RightSideBar";
import SymbolContainer from "../Compenents/SymbolContainer";

function About() {

    const [Data, setData] = useState([]);
    
      const location = useLocation();
    
      useEffect(() => {
        fetch(
          "https://api.forecast.ac/stock?&symbol=" +
            location.pathname.split("/")[2] +
            ""
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            setData(data[0]);
          });
      }, []);
    

  return (
    <div id="__next">
      <div className="bg-primary-background">
        <div
          id="g_id_onload"
          data-client_id="176145947272-cs9kfn9npp6lt8f4derbf9pu547v658i.apps.googleusercontent.com"
          data-auto_prompt="false"
          data-cancel_on_tap_outside="true"
          data-itp_support="true"
        />
        <div
          className="Layout_layoutContainer__NH7Il flex flex-row min-h-screen desktopXxl|mx-auto Layout_layoutBorder__qMIMd bg-primary-background border-x tabletSm-down|border-x-0 border-primary-hairline"
          id="Layout"
        >
          <LeftSideBar />

          <div className="Main_mainContainer-CORE1-1151__HSY7M Main_main__3C253 min-h-screen w-full">
            <div
              className="MainHeader_mainHeader__L2Fez top-0 tabletMd-down|left-0 tabletMd-down|right-0 z-25 fixed bg-primary-background border-b border-primary-hairline tabletSm|dark|border-l tabletMd|border-l tabletSm-down|border-x-0 MainHeader_mainHeaderBorder__RIXJZ border-r border-primary-hairline"
              id="app-main-header"
            >
              <MainHeaderMobile />
              <MainHeader />
              <TimeLine />
            </div>
            <div
              className="Body_container__f_XJ0 flex flex-column desktopXxl|pr-0 tabletSm-down|pb-12 Body_containerBorder__3T8GJ border-primary-hairline border-l tabletSm-down|border-x-0"
              style={{ paddingBottom: 0 }}
            >
              <div className="Body_content__07Yri w-full max-w-[980px] tabletMd-down|max-w-none">
                <div className="SymbolPage_container__tRK07 tabletMd|max-w-[970px] w-full grid gap-2.5">
                  <SymbolContainer props={Data} />
                  <RightSideBar />
                </div>
              </div>
            </div>
          </div>

          <div className="Toastify" />
        </div>
      </div>
    </div>
  )
}

export default About